* {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
#map {
  width: 100%;
  height: 100%;
}

.stop-edit svg {
  background: red;
}

.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
